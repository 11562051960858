import React, { useEffect, useState } from "react"
import NavigatorSteps from "../../../components/atoms/navigator-steps/navigator-steps"
import { sessionStorage } from "../../../shared/utils/storage"
import userSessionStorage from "../../../shared/utils/userSessionStorage"
import FormContactData from "../../../components/molecules/form-contact-data/form-contact-data"
import homeLink from "../../../shared/utils/siteUrl"
import { useForm } from "react-hook-form"
import "./second-step-content.scss"

const SecondStepContent = props => {
  const { title, registerInfo } = props

  const [defaultData] = useState(sessionStorage.getItem("infoRegister"))
  const session = new userSessionStorage()

  const {
    register,
    handleSubmit,
    formState,
    errors,
    setValue,
    setError,
    trigger,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: defaultData?.email || "",
      cellphone: defaultData?.cellphone || "",
      password: defaultData?.password || "",
    },
  })

  const onSubmit = data => {
    let infoRegister = sessionStorage.getItem("infoRegister")
    data.cellphone && (infoRegister.cellphone = data.cellphone)
    data.email && (infoRegister.email = data.email)
    infoRegister.password = data.password

    sessionStorage.setItem("infoRegister", infoRegister)

    window.location.href = homeLink + "registro/paso-3"
  }

  useEffect(() => {
    if (
      !defaultData ||
      (!defaultData.email && !defaultData.cellphone) ||
      !defaultData.name ||
      !defaultData.lastname ||
      !defaultData.gender ||
      !defaultData.day ||
      !defaultData.month ||
      !defaultData.year
    ) {
      window.location.href = homeLink + "registro/"
    }

    if (defaultData.cellphone && defaultData.cellphone.length > 10) {
      setValue("cellphone", session.getNoPrefix(defaultData.cellphone))
      trigger("cellphone")
    }
  }, [])

  return (
    <div className="f-first-step-content">
      <h2 className="f-title--purple f-form-register-title">{title}</h2>

      <NavigatorSteps totalSteps={4} activeStep={2} />

      <h2 className="f-title--purple f-register-title">Datos de contacto</h2>

      <FormContactData
        register={register}
        errors={errors}
        formState={formState}
        onSubmit={handleSubmit(onSubmit)}
        citysInfo={registerInfo.ciudad}
        setError={setError}
        deparmentInfo={registerInfo.departamentos}
      />
    </div>
  )
}

export default SecondStepContent
