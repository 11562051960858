import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import CustomInputText from "../../../shared/components/atoms/inputText/InputText"
import CustomCheckboxNative from "../../../shared/components/atoms/custom-checkbox-native/custom-checkbox-native"
import Button from "../../../shared/components/atoms/button/button"
import { sessionStorage } from "../../../shared/utils/storage"
import { Popover } from "@material-ui/core"
import {
  EMAIL_PATTERN,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  successStatus,
} from "../../../shared/constants/appConstants"
import homeLink from "../../../shared/utils/siteUrl"
import RegisterService from "../../../shared/services/register-service"
import "./form-contact-data.scss"

const FormContactData = props => {
  const {
    register,
    errors,
    formState,
    onSubmit,
    isUpdate = false,
    className,
    setError,
  } = props

  const [defaultData] = useState(sessionStorage.getItem("infoRegister"))
  const [isValidUserName, setIsValidUserName] = useState(false)
  const cellLength = process.env.CELL_DIG ? process.env.CELL_DIG : 10

  const validateCellOrMail = (type, value, userId = defaultData?.userId) => {
    if (
      (type === "cellphone" && value.length !== Number(cellLength)) ||
      (type === "email" && !EMAIL_PATTERN.test(value.toString()))
    )
      return false

    let payload = {
      username:
        type === "cellphone" ? process.env.COUNTRY_PHONE_PREFIX + value : value,
      userId: userId,
    }

    RegisterService.validateUser(payload)
      .then(res => {
        if (res.status === successStatus) {
          setIsValidUserName(true)
        } else {
          setIsValidUserName(false)
          setError(type, {
            type: "manual",
            message: res.userMessage,
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  //To open the propover popup
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  //For open pop up terms and conditions
  const handleClickPopup = event => {
    setAnchorEl(event.currentTarget)
  }

  //For close pop up terms and conditions
  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  React.useEffect(() => {
    defaultData.email && validateCellOrMail("email", defaultData.email)
    defaultData.cellphone &&
      validateCellOrMail("cellphone", defaultData.cellphone)
  }, [])

  return (
    <form
      className={
        "f-register-form f-rows-container " + (className ? className : "")
      }
      onSubmit={onSubmit}
      autoComplete="off"
    >
      <div className="f-row f-row-email">
        <div className="f-container-input">
          <CustomInputText
            fullWidth={true}
            label="Correo electronico"
            id="email-input"
            required={true}
            inputProps={{
              name: "email",
              maxLength: 100,
              placeholder: "petys@petys.com",
            }}
            inputRef={register({
              required: "Este campo es requerido",
              maxLength: {
                value: 100,
                message: "Se requieren máximo 100 caracteres",
              },
              pattern: {
                value: EMAIL_PATTERN,
                message: "El email es inválido",
              },
            })}
            error={errors.email ? true : false}
            errorText={
              errors.email
                ? errors.email
                  ? errors.email.message
                  : "Campo inválido"
                : ""
            }
            onBlur={e => validateCellOrMail("email", e.target.value)}
          />
        </div>

        <div className="f-container-input">
          <CustomInputText
            fullWidth={true}
            label="Tu celular"
            id="cellphone"
            required={true}
            inputProps={{
              name: "cellphone",
              maxLength: cellLength,
              placeholder: "3001233212",
              type: "number",
            }}
            inputRef={register({
              required: "Este campo es requerido",
              maxLength: {
                value: cellLength,
                message: "Se requieren máximo " + cellLength + " caracteres",
              },
              minLength: {
                value: cellLength,
                message: "Se requieren mínimo " + cellLength + " caracteres",
              },
            })}
            error={errors.cellphone ? true : false}
            errorText={
              errors.cellphone
                ? errors.cellphone
                  ? errors.cellphone.message
                  : "Campo inválido"
                : ""
            }
            onBlur={e => validateCellOrMail("cellphone", e.target.value)}
          />
        </div>
      </div>

      <div className="f-row f-row-password">
        <div className="f-container-input">
          <CustomInputText
            fullWidth={true}
            label="Contraseña"
            id="password"
            required={true}
            inputProps={{
              name: "password",
              minLength: PASSWORD_MIN_LENGTH,
              maxLength: PASSWORD_MAX_LENGTH,
              placeholder: "******",
            }}
            inputRef={register({
              required: "Este campo es requerido",
              minLength: {
                value: PASSWORD_MIN_LENGTH,
                message:
                  "Se requieren mínimo " + PASSWORD_MIN_LENGTH + " caracteres",
              },
              maxLength: {
                value: PASSWORD_MAX_LENGTH,
                message:
                  "Se requieren máximo " + PASSWORD_MAX_LENGTH + " caracteres",
              },
              // pattern: {value: PASSWORD_PATTERN, message: 'Campo inválido'}
            })}
            error={errors.password ? true : false}
            errorText={
              errors.password
                ? errors.password.message
                  ? errors.password.message
                  : "Campo inválido"
                : ""
            }
          />
          <i
            className="fal fa-exclamation-circle f-icon-popup"
            style={{ transform: "translateY(50%)" }}
            onClick={handleClickPopup}
          ></i>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopup}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <div className="f-propover-terms">
              <h4 className="f-propover-title">
                Crea una contraseña para ingresar a tu cuenta en Petys
              </h4>
            </div>
          </Popover>
        </div>
      </div>

      <div className={"f-buttons-container"}>
        <Button
          className="f-btn-purple f-button-prev"
          title={isUpdate ? "Cancelar" : "Atrás"}
          onClick={() => {
            window.location.href = homeLink + "registro/paso-1"
          }}
        />

        <Button
          className="f-primary-purple f-button-next"
          typeButton="button"
          type="submit"
          title={isUpdate ? "Actualizar" : "Siguiente"}
          disabled={!(formState.isValid && isValidUserName === true)}
        />
      </div>
    </form>
  )
}

export default FormContactData
