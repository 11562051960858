import { axiosInstance } from './axios-instance';
import * as endpoints from "../constants/apiConstants";
class RegisterService {

  static postRegister(payload) {
    return axiosInstance.post(endpoints.REGISTER_USER, payload).then(res => res.data)
  }
  static validateUser(payload) {
    return axiosInstance.post(endpoints.VALIDATE_USER, null, { params: payload}).then(res => res.data)
  }
  static postConfirmation(payload) {
    return axiosInstance.post(endpoints.CONFIRMATION_USER, payload).then(res => res.data)
  }
  static postResendCode(payload) {
    return axiosInstance.post(endpoints.RESEND_CODE, payload).then(res => res.data)
  }
  static getUserInfoCode(userId) {
    return axiosInstance.get(endpoints.INFO_USER_CODE + `?userId=${userId}`).then(res => res.data)
  }
  static deletePet(payload) {
    return axiosInstance.delete(endpoints.DELETE_PET_REGISTER, { params : payload }).then(res => res.data)
  }
}

export default RegisterService;