import React from "react"
import RegisterLayout from "../../shared/components/molecules/register-layout/register-layout"
import useRegisterInfo from "../../shared/hooks/useRegister"
import SecondStepContent from "../../components/organisms/second-step-content/second-step-content"
import SEO from "../../shared/components/atoms/seo/seo"
import Layout from "../../shared/components/organisms/layout/layout"
import "../../shared/styles/index.scss"

const SecondStep = () => {
  const { registerInfo } = useRegisterInfo()

  return (
    <Layout activeMenu={5} shopButton={false} showHeader={false}>
      <SEO
        title={registerInfo.metaTitulo ? registerInfo.metaTitulo : ""}
        description={
          registerInfo.metaDescripcion
            ? registerInfo?.metaDescripcion?.metaDescripcion
            : ""
        }
        slug={registerInfo.slug ? registerInfo.slug : ""}
        lang="ES"
      />
      <RegisterLayout redirection="registro/">
        <SecondStepContent
          title={registerInfo.tituloIntroductorio}
          registerInfo={registerInfo}
        />
      </RegisterLayout>
    </Layout>
  )
}

export default SecondStep
